import React from "react";
import {
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import axios from "axios";
import HalfTone from "assets/img/bgs/halftone.png";

var extendFormStyle = {
  backgroundImage: "url(" + HalfTone + ")",
};

class ContactSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showExtendedForm: false };
  }

  // callAPI() {
  //     fetch("http://localhost:9000/testAPI")
  //         .then(res => res.text())
  //         .then(res => this.setState({ apiResponse: res }));
  // }

  // componentWillMount() {
  //     this.callAPI();
  // }

  handleSubmit(e) {
    e.preventDefault();

    axios({
      method: "POST",
      url: "https://zsimarket.usermd.net/contact",
      data: {
        name: document.getElementById("name").value,
        email: document.getElementById("email").value,
        message: document.getElementById("message").value,
        weddingDate: document.getElementById("weddingDatetime").value,
        weddingPlace: document.getElementById("weddingPlace").value,
        partyPlace: document.getElementById("partyPlace").value,
        guestNumber: document.getElementById("guestNumber").value,
        budget: document.getElementById("budget").value,
      },
    }).then((response) => {
      if (response.data.msg === "success") {
        alert("Wiadomość wysłana");
        document.getElementById("contact-form").reset();
      } else if (response.data.msg === "fail") {
        alert("Message failed to send.");
      }
    });
  }

  render() {
    return (
      <>
        <div className="container">
          <Row>
            <p className="h5 p-3 m-3 font-weight-normal text-center mx-auto px-5">
              Napiszcie do nas przez formularz, a my prześlemy Wam
              spersonalizowaną ofertę!
            </p>
            <form
              className="w-100 mx-auto mb-5 text-center p-4 col-md-10"
              id="contact-form"
              onSubmit={this.handleSubmit.bind(this)}
              method="POST"
            >
              <Row>
                <div className="form-group col-md-6 font-weight-normal">
                  <label for="name ">Imię i Nazwisko</label>
                  <input
                    type="text"
                    className="form-control text-center"
                    id="name"
                  />
                </div>
                <div className="form-group col-md-6 font-weight-normal">
                  <label for="exampleInputEmail1">Twój E-mail</label>
                  <input
                    type="email"
                    className="form-control text-center"
                    id="email"
                    aria-describedby="emailHelp"
                  />
                </div>
              </Row>
              <div className="my-3 px-3" style={extendFormStyle} role="button">
                <p
                  className="h5 p-3 m-1 font-weight-normal text-center mx-auto px-5 "
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.setState({
                      showExtendedForm: !this.state.showExtendedForm,
                    });
                  }}
                >
                  Szczegóły Uroczystości <i class="fa fa-chevron-down"></i>
                </p>
                <div className={this.state.showExtendedForm ? "" : "d-none"}>
                  <Row>
                    <div className="form-group col-md-6 font-weight-normal">
                      <label for="weddingPlace ">Planowane Miejsce Ślubu</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        id="weddingPlace"
                      />
                    </div>
                    <div className="form-group col-md-6 font-weight-normal">
                      <label for="exampleInputEmail1">
                        Planowane Miejsce Wesela
                      </label>
                      <input
                        type="partyPlace"
                        className="form-control text-center"
                        id="partyPlace"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </Row>
                  <Row>
                    <div className="form-group col-md-6 font-weight-normal">
                      <label htmlFor="placeSelect">Planowana Liczba Osób</label>
                      <select
                        name="carlist"
                        form="carform"
                        className="form-control text-center"
                        id="guestNumber"
                      >
                        <option value="" selected disabled></option>
                        <option value="Poniżej 50">Poniżej 50</option>
                        <option value="50 - 100">50 - 100</option>
                        <option value="100 - 200">100 - 200</option>
                        <option value="Powyżej 200">Powyżej 200</option>
                      </select>
                    </div>
                    <div className="form-group col-md-6 font-weight-normal">
                      <label htmlFor="placeSelect">
                        Planowany Budżet
                      </label>
                      <select
                        name="carlist"
                        form="carform"
                        className="form-control text-center"
                        id="budget"
                      >
                        <option value="" selected disabled></option>
                        <option value="Poniżej 30 000 zł">
                          Poniżej 30 000 zł
                        </option>
                        <option value="30 000 - 60 000 zł">
                          30 000 - 60 000 zł
                        </option>
                        <option value="60 000 - 100 000 zł">
                          60 000 - 100 000 zł
                        </option>
                        <option value="Powyżej 100 000 zł">
                          Powyżej 100 000 zł
                        </option>
                      </select>
                    </div>
                  </Row>

                  <Row className="justify-content-center">
                    <Col className="">
                      <label htmlFor="weddingDatetime">Podaj Datę Ślubu</label>
                      <InputGroup id="WeddingDatePicker" className="date justify-content-center mb-3">
                        <ReactDatetime
                          id="weddingDatetime"
                          wrapperClassName={"w-100"}
                          className="text-center w-90 w-md-50"
                          timeFormat={false}
                          inputProps={{
                            placeholder: "Możesz pominąć to pole",
                            id: "weddingDatetime",
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <span className="glyphicon glyphicon-calendar">
                              <i
                                aria-hidden={true}
                                className="fa fa-calendar"
                              />
                            </span>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="form-group font-weight-normal">
                <label for="message">Wiadomość</label>
                <textarea
                  className="form-control uneditable-input text-center"
                  rows="5"
                  id="message"
                ></textarea>
              </div>

              {/* <p className='text-right'><a href='/polityka-prywatnosci.docx' target='_blank' style={{color:"#66615b", textDecoration:"underline"}}>Polityka prywatności</a></p> */}
              <p className="text-left" style={{ fontSize: "10px" }}>
                Administratorem danych osobowych podanych w formularzu
                kontaktowym jest Magdalena Stachurska prowadząca działalność
                gospodarczą pod firmą Magdalena Stachurska, ul. Lecha 1/25
                25-622, Kielce, NIP: 9592045709, Regon: 389801974. Kontakt
                z administratorem w sprawach dotyczących przetwarzania danych
                osobowych: [abcmagda6@gmail.com]. Twoje dane podane w formularzu
                kontaktowym są przetwarzane w celu udzielenia odpowiedzi na
                Twoje zapytanie. Jeśli wyrazisz na to zgodę, Twoje dane są też
                przetwarzane w celach marketingowych. Masz w szczególności prawo
                dostępu do swoich danych i ich usunięcia. Szczegóły dotyczące
                przetwarzania Twoich danych osobowych i przysługujących Ci praw
                znajdziesz w Polityce prywatności. Jeśli chcesz otrzymywać ode
                mnie informacje, potrzebuję na to Twojej zgody. Wyrażenie zgody
                jest dobrowolne i możesz ją cofnąć w dowolnym momencie, ze
                skutkiem na przyszłość, pisząc na adres podany powyżej albo
                klikając na link dostępny w przesyłanych Ci wiadomościach.
              </p>
              <button
                type="submit"
                className="btn btn-round px-5 sec-colour-bg"
              >
                Wyślij
              </button>
            </form>
          </Row>
        </div>
      </>
    );
  }
}
export default ContactSummary;
