import React from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import Background from "assets/img/pakiety_summary.jpg";
import "./OfferSummaryHomepage.scss"

let displayShowMore;
let bundleDescription;

if (window.location.pathname !== "/oferta") {
  displayShowMore = "flex";
  bundleDescription = `Każdą Parę traktujemy indywidualnie. Zapytajcie o szczegóły i wybierzcie coś dla siebie!`;
} else {
  displayShowMore = "none";
  bundleDescription =
    "Każdą Parę traktujemy indywidualnie. Zapytajcie o szczegóły i wybierzcie coś dla siebie!";
}

const minHeight = {
  display: "flex",
  flexdirection: "column",
};

const secRowStyle = {
  backgroundImage: `url(${Background})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
};

let OfferSummaryHomepage = () => {
  return (
    <>
      <div className="section-dark pt-0 border-top h-100 ">
        <div className="container-fluid">
          <Row className="text-center">
            <p
              className="h2 border-bottom border-right p-4 pr-5 pl-5 m-0 w-100 font-weight-normal"
              style={{ backgroundColor: "white" }}
            >
              Pakiety
            </p>
          </Row>
          <Row style={secRowStyle} className="border-bottom pt-5 pb-5">
            <Col lg="4" className="text-center" style={minHeight}>
              <Card className="m-3 inherit2 rounded-0 mt-4">
                <CardImg
                  className="rounded-0 offer-summary__card-image"
                  top
                  src={require("assets/img/kompleksowej_organizacji.jpg")}
                  alt="Card image cap p-2"
                />
                <CardBody>
                  <CardTitle className="text-white h5 font-weight-bold">
                    Kompleksowa organizacja ślubu i wesela
                  </CardTitle>
                  <CardText className="text-white">
                    Zaplanujemy wszystkie elementy związane z uroczystością oraz
                    zapewnimy wszystkich usługodawców
                  </CardText>
                  <CardText className="text-white my-auto font-weight-bold">
                    od 9 000 zł
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" className="text-center" style={minHeight}>
              <Card className="m-3 inherit2 rounded-0 mt-4">
                <CardImg
                  className="rounded-0 offer-summary__card-image"
                  top
                  src={require("assets/img/czesciowej_organizacji.jpg")}
                  alt="Card image cap"
                />
                <CardBody>
                  <CardTitle className="text-white h5 font-weight-bold">
                    Częściowa organizacja dnia ślubu i wesela
                  </CardTitle>
                  <CardText className="text-white my-auto">
                    Uzupełnimy Waszą listę usługodawców
                  </CardText>
                  <br />
                  <CardText className="text-white my-auto font-weight-bold">
                    od 3000 zł + 20% od podpisanej umowy
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" className="text-center" style={minHeight}>
              <Card className="m-3 inherit2 rounded-0 mt-4">
                <CardImg
                  className="rounded-0 offer-summary__card-image"
                  top
                  src={require("assets/img/koordynacji_organizacji.jpg")}
                  alt="Card image cap"
                />
                <CardBody>
                  <CardTitle className="text-white h5 font-weight-bold">
                    Koordynacja dnia ślubu i wesela
                  </CardTitle>
                  <CardText className="text-white">
                    Zadbamy o każdy szczegół w tym ważnym dla Was dniu. Zgramy
                    wszystkich podwykonawców i zaopiekujemy się Wami.
                  </CardText>
                  <CardText className="text-white my-auto font-weight-bold">
                    od 4500 zł
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Row className={`w-100 text-center mx-auto`}>
              <div
                id="bundleDescription"
                className="h4 font-weight-normal text-white m-5 w-100 inherit2 p-5 "
              >
                {bundleDescription}
              </div>
              <a
                href="/oferta"
                className={`btn thrd-colour-bg mx-auto d-${displayShowMore}`}
              >
                Zobacz pełną ofertę
              </a>
            </Row>
          </Row>
        </div>
      </div>
    </>
  );
};

export default OfferSummaryHomepage;
