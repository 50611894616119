import React, { useRef } from "react";

import { Row, Col } from "reactstrap";

import HalfTone from "assets/img/bgs/halftone.png";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import ContactSummary from "components/Summaries/ContactSummary";
import ContactForm from "components/Summaries/ContactForm";

function AboutMe() {
  var Profiletyle = {
    backgroundImage: "url(" + HalfTone + ")",
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  const myRef = useRef(null);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  return (
    <>
      <IndexNavbar exeScroll={scrollToRef} myRefe={myRef} />
      <ProfilePageHeader />
      <div className="section profile-content w-100 pb-0">
        <div className="owner pb-5 mb-5 mb-sm-0" style={Profiletyle}>
          <div className="avatar pb-1">
            <img
              alt="..."
              className="img-circle img-no-padding border img-responsive"
              src={require("assets/img/contact_portrait.png")}
            />
          </div>
          <div className="name">
            <p className="title h2 font-weight-normal mt-0 mb-0">
              Ogarniacze Ślubów
              <br />
            </p>
            <h6 className="description sec-colour">Wedding Planners</h6>
          </div>
        </div>

        <br />
        <Row className="w-100 mx-auto my-5 pt-5 pt-lg-1">
          <Col
            md="7"
            className="my-auto text-center border-left border-top border-bottom ml-sm-5 prim-colour-bg p-4"
          >
            <div
              className="info sec-colour h4 pt-3"
              style={{ marginTop: "-180px" }}
            >
              <div className="icon ">
                <img
                  src={require("assets/img/logomsblack.png")}
                  alt="Magda Stachurska Logo"
                  style={{ width: "100px" }}
                  className={"mb-3"}
                />
              </div>
              <div className="description">
                <p className="info-title sec-colour"></p>
              </div>
            </div>

            <p className="h2 font-weight-normal mt-5">Magdalena Stachurska</p>
            <p className="font-weight-normal px-4 pt-3">
              Właścicielka i założycielka agencji Ogarniacze Ślubów. Jest
              doświadczoną konsultantką ślubną, której pasją jest tworzenie
              niezapomnianych chwil.
            </p>
            <p className="font-weight-normal py-3 px-4 ">
              Jej zdolności organizacyjne i dbałość o detale sprawiają, że każda
              ceremonia jest dopracowana w najmniejszym szczególe. Zawsze
              dokłada wszelkich starań, żeby spełnić marzenia Par o ich idealnym
              dniu.
            </p>
          </Col>
          <Col md="4" className="px-0">
            <img
              alt="Magdalena Stachursa Planowanie wesel"
              className="img-no-padding border img-responsive"
              src={require("assets/img/about_us_magda.jpg")}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row className="w-100 mx-auto my-5 pt-5 pt-lg-1 align-items-center">
          <Col md="4" className="px-0 ml-sm-5">
            <img
              alt="Magdalena Stachursa Planowanie wesel"
              className="img-no-padding border img-responsive"
              src={require("assets/img/about_us_ola.jpg")}
              style={{ width: "100%" }}
            />
          </Col>
          <Col
            md="7"
            className="my-auto text-center border-left border-top border-bottom prim-colour-bg p-4"
          >
            <p className="h2 font-weight-normal mt-5">Aleksandra Czyżykowska</p>
            <p className="font-weight-normal px-4 pt-3">
              Wyróżnia się wyjątkowym wyczuciem stylu i umiejętnością
              kreatywnego rozwiązywania problemów.
            </p>
            <p className="font-weight-normal py-3 px-4 ">
              Jako wedding plannerka stawia na indywidualne podejście do każdej
              Pary, tworząc spersonalizowane uroczystości, które odzwierciedlają
              ich wyjątkową historię. Dzięki jej energii i entuzjazmowi,
              planowanie ślubu staje się procesem pełnym radości i spokoju.
            </p>
          </Col>
        </Row>
        <Row className="w-100 mx-auto my-5 pt-5 pt-lg-1">
          <Col
            md="7"
            className="my-auto text-center border-left border-top border-bottom ml-sm-5 prim-colour-bg p-4"
          >
            <p className="h2 font-weight-normal mt-5">Milena Miszczyk</p>
            <p className="font-weight-normal px-4 pt-3">
              To profesjonalistka, która z sercem podchodzi do organizacji
              każdego ślubu. Jej priorytetem jest, aby wszystkie elementy dnia
              ślubu były ze sobą harmonijnie zgrane, tworząc niezapomnianą
              atmosferę.
            </p>
            <p className="font-weight-normal py-3 px-4 ">
              Znana ze swojej elastyczności i umiejętności pracy pod presją.
              Dzięki niej nawet najbardziej wymagające projekty przebiegają
              bezstresowo i zgodnie z planem.
            </p>
          </Col>
          <Col md="4" className="px-0">
            <img
              alt="Magdalena Stachursa Planowanie wesel"
              className="img-no-padding border img-responsive"
              src={require("assets/img/about_us_milena.jpg")}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </div>
      <ContactSummary refProp={myRef} />
      <ContactForm />
      <DemoFooter />
    </>
  );
}

export default AboutMe;
