export const imagesOkil3 = [
  { src: require("../assets/img/okil3/1.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/3.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/10.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/11.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/13.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/14.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/15.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/16.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/19.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/22.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/23.jpg"), width: 3, height: 2 },
  //
  { src: require("../assets/img/okil3/2.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/4.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/5.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/6.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/7.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/8.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/9.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/12.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/17.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/18.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/20.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/21.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/24.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/25.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/26.jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil3/27.jpg"), width: 3, height: 2 },
];

export const imagesOkil2 = [
  { src: require("../assets/img/okil2/okil (1).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (2).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (3).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (4).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (5).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (6).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (7).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (8).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (9).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (10).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (11).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (12).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (13).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (14).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (15).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (16).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (17).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (18).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (19).jpg"), width: 3, height: 2 },
  { src: require("../assets/img/okil2/okil (20).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (21).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (22).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (23).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (24).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (25).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (26).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (27).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (28).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (29).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (30).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (31).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (32).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (33).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (34).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (35).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (36).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (37).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (38).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (39).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (40).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (41).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (42).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (43).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (44).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (45).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (46).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (47).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (48).jpg"), width: 3, height: 2 },
//   { src: require("../assets/img/okil2/okil (49).jpg"), width: 3, height: 2 },
];
/*
  { src: require("../assets/img/okil1/okil (1).JPG"), width: 3, height: 2 },
    { src: require("../assets/img/okil1/okil (6).JPG"), width: 3, height: 2 },
    { src: require("../assets/img/okil1/okil (20).JPG"), width: 3, height: 2 },
    { src: require("../assets/img/okil1/okil (13).JPG"), width: 3, height: 2 },
         { src: require("../assets/img/okil1/okil (25).JPG"), width: 3, height: 2 },
    { src: require("../assets/img/okil1/okil (27).JPG"), width: 3, height: 2 },
    { src: require("../assets/img/okil1/okil (30).JPG"), width: 3, height: 2 },
    { src: require("../assets/img/okil1/okil (39).JPG"), width: 3, height: 2 },
    { src: require("../assets/img/okil1/okil (48).JPG"), width: 3, height: 2 },
    { src: require("../assets/img/okil1/okil (50).JPG"), width: 3, height: 2 },
         { src: require("../assets/img/okil1/okil (54).JPG"), width: 3, height: 2 },
    { src: require("../assets/img/okil1/okil (56).JPG"), width: 3, height: 2 },
    { src: require("../assets/img/okil1/okil (60).JPG"), width: 3, height: 2 },
    { src: require("../assets/img/okil1/okil (61).JPG"), width: 3, height: 2 },
    { src: require("../assets/img/okil1/okil (66).JPG"), width: 3, height: 2 },
  */
export const imagesOkil1 = [
  { src: require("../assets/img/okil1/okil (2).JPG"), width: 3, height: 2 },
  { src: require("../assets/img/okil1/okil (3).JPG"), width: 3, height: 2 },
  { src: require("../assets/img/okil1/okil (4).JPG"), width: 3, height: 2 },
  { src: require("../assets/img/okil1/okil (5).JPG"), width: 3, height: 2 },
  { src: require("../assets/img/okil1/okil (7).JPG"), width: 3, height: 2 },
  { src: require("../assets/img/okil1/okil (8).JPG"), width: 3, height: 2 },
  { src: require("../assets/img/okil1/okil (9).JPG"), width: 3, height: 2 },
  { src: require("../assets/img/okil1/okil (10).JPG"), width: 3, height: 2 },
  { src: require("../assets/img/okil1/okil (11).JPG"), width: 3, height: 2 },
  { src: require("../assets/img/okil1/okil (12).JPG"), width: 3, height: 2 },
  { src: require("../assets/img/okil1/okil (14).JPG"), width: 3, height: 2 },
  { src: require("../assets/img/okil1/okil (15).JPG"), width: 3, height: 2 },
  { src: require("../assets/img/okil1/okil (16).JPG"), width: 3, height: 2 },
  { src: require("../assets/img/okil1/okil (17).JPG"), width: 3, height: 2 },
  { src: require("../assets/img/okil1/okil (18).JPG"), width: 3, height: 2 },
  { src: require("../assets/img/okil1/okil (19).JPG"), width: 3, height: 2 },
  { src: require("../assets/img/okil1/okil (21).JPG"), width: 3, height: 2 },
  { src: require("../assets/img/okil1/okil (22).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (23).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (24).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (26).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (28).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (29).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (31).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (32).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (33).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (34).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (35).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (36).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (37).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (38).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (40).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (41).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (42).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (43).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (44).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (45).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (46).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (47).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (49).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (50).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (51).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (52).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (53).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (55).JPG"), width: 3, height: 2 },

  // { src: require("../assets/img/okil1/okil (57).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (58).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (59).JPG"), width: 3, height: 2 },

  // { src: require("../assets/img/okil1/okil (62).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (63).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (64).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (65).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (67).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (68).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (69).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (70).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (71).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (72).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (73).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (74).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (75).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (76).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (77).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (78).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (79).JPG"), width: 3, height: 2 },

  // { src: require("../assets/img/okil1/okil (80).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (81).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (82).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (83).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (84).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (85).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (86).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (87).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (88).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (89).JPG"), width: 3, height: 2 },

  // { src: require("../assets/img/okil1/okil (90).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (91).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (92).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (93).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (94).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (95).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (96).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (97).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (98).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (99).JPG"), width: 3, height: 2 },

  // { src: require("../assets/img/okil1/okil (100).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (101).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (102).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (103).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (104).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (105).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (106).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (107).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (108).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (109).JPG"), width: 3, height: 2 },

  // { src: require("../assets/img/okil1/okil (110).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (111).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (112).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (113).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (114).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (115).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (116).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (117).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (118).JPG"), width: 3, height: 2 },
  // { src: require("../assets/img/okil1/okil (119).JPG"), width: 3, height: 2 },

  // { src: require("../assets/img/okil1/okil (120).JPG"), width: 3, height: 2 },
];
