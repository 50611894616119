import React, { useRef, useState, useCallback } from "react";
import { Row, Col } from "reactstrap";
import HalfTone from "assets/img/bgs/halftone.png";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import ContactSummary from "components/Summaries/ContactSummary";
import ContactForm from "components/Summaries/ContactForm";
import Carousel from "react-images";
import { imagesOkil1, imagesOkil2, imagesOkil3 } from "./FestivalData";

var contentStyle = {
  backgroundImage: "url(" + HalfTone + ")",
  maxWidth: "100vw",
};

function FestivalView() {
  var h2Style = {
    background: "white",
    marginTop: window.innerWidth < 500 ? "-130px" : "-98px",
    zIndex: "2",
    border: "1px solid black",
  };

  var h3Style = {
    background: "white",
    backgroundImage: "url(" + HalfTone + ")",
    height: window.innerWidth < 500 ? "110px" : "85px",
    marginTop: "0",
    zIndex: "2",
    border: "1px solid black",
    minHeight: "unset",
    fontWeight: "bold!important",
  };

  const myRef = useRef(null);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const [okil3Current, setOkil3Current] = useState(0);
  const [okil1Current, setOkil1Current] = useState(0);

  return (
    <>
      <IndexNavbar exeScroll={scrollToRef} myRefe={myRef} />
      <ProfilePageHeader />
      <div className="section profile-content sec-colour text-center pb-0">
        <Row className="section pt-0 mt-0 pb-0 mx-auto" style={contentStyle}>
          <p
            className="title font-weight-normal p-4 w-75 mx-auto h2 mb-0"
            style={h2Style}
          >
            Ogarniam Kiecę i Lecę!
          </p>

          <p
            className="title font-weight-normal py-4 w-50 mx-auto h3 mb-0"
            style={h3Style}
          >
            Festiwal Sukni Ślubnych
          </p>

          <div className="container-fluid w-10 0">
            <Row>
              <img
                style={{
                  width: "100%",
                  height: "60vh",
                  objectFit: "cover",
                  marginTop: window.innerWidth < 500 ? "-110px" : "-85px",
                }}
                className="img-responsive border-right"
                alt="Magdalena Stachurska"
                src={require("assets/img/okil.jpg")}
              />
            </Row>
          </div>

          <Row className="border w-100 mx-auto">
            <div className="text-center w-100 ">
              <p
                className="h2 border-bottom border-right p-4 m-0 font-weight-normal text-dark w-100"
                style={{ backgroundColor: "white" }}
              >
                Na czym to polega?
              </p>
            </div>
            <p className="pb-3 text-center text-dark w-75 mx-auto mt-4">
              Jeśli chcesz dać drugie życie swojej sukni ślubnej lub marzysz o
              znalezieniu tej jedynej w duchu less waste to miejsce jest idealne
              dla Ciebie. W wydarzeniu oprócz sukien ślubnych znajdziesz pomoc
              specjalistów z branży. W wybraniu odpowiedniej kreacji pomoże Ci
              Konsultantka Ślubna oraz krawcowa, a wyselekcjonowani usługodawcy
              przedstawią Ci swoją ofertę.
            </p>
            <p className="pb-3 text-center text-dark w-75 mx-auto">
              Wypełnij formularz rejestracyjny: - podaj poprawne dane
              kontaktowe - uwzględnij w cenie sukni wszelkie opłaty (cenę
              prania, naprawę sukni, opłatę wstępną oraz ewentualną prowizję) -
              opisz suknie i dodatki oraz dodaj zdjęcia - masz uwagi lub prośby
              uwzględnij je w formularzu 
            </p>
              
            <p className="pb-3 text-center text-dark w-75 mx-auto">
              Podpisz umowę - jeśli w ciągu 3 dni
              roboczych nie dostałaś informacji zwrotnej lub umowy sprawdź
              folder SPAM 
            </p>
              
            <p className="pb-3 text-center text-dark w-75 mx-auto">
              Prześlij opłatę wstępną
            </p>
              
            <p className="pb-3 text-center text-dark w-75 mx-auto">
              Daj sukience drugie życie - nie musisz być na miejscu festiwalu -
              prześlij sukienkę (koszt przesyłki ponosi wystawca) odpowiednio
              wcześniej lub przekaż ja organizatorowi w dniach wskazanych w
              formularzu 
            </p>
              
            <p className="pb-3 text-center text-dark w-75 mx-auto">
              Jeśli Twoja suknia się sprzeda organizator pobiera
              20% kwoty, za którą suknia została sprzedana.
            </p>
              
            <p className="pb-3 text-center text-dark w-75 mx-auto">
               Jeśli Twoja suknia się nie sprzeda odbierz ją od organizatora lub oczekuj
              przesyłki zwrotnej (koszt przesyłki również ponosi wystawca)
            </p>
          </Row>

          <div className="text-center w-100">
            <p
              className="h2 border-bottom border-right p-4 m-0 mt-5 w-50 mx-auto font-weight-normal text-dark"
              style={{ backgroundColor: "white" }}
            >
              Edycja 3
            </p>
          </div>
          <Row className="px-md-5 h-75">
            <Col
              lg={okil3Current < 11 ? "8" : "4"}
              sm="12"
              className="mx-auto text-center thrd-colour"
            >
              <Carousel
                trackProps={{
                  onViewChange: (num) => {
                    setOkil3Current(num);
                  },
                }}
                frameProps={{ autoSize: "height" }}
                views={[...imagesOkil3].map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Col>
          </Row>

          <div className="text-center w-100">
            <p
              className="h2 border-bottom border-right p-4 m-0 mt-5 w-50 mx-auto font-weight-normal text-dark"
              style={{ backgroundColor: "white" }}
            >
              Edycja 2
            </p>
          </div>
          <Row className="px-md-5 h-75">
            <Col lg="8" sm="12" className="mx-auto text-center thrd-colour">
              <Carousel
                frameProps={{ autoSize: "height" }}
                views={[...imagesOkil2].map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Col>
          </Row>

          <div className="text-center w-100">
            <p
              className="h2 border-bottom border-right p-4 m-0 mt-5 w-50 mx-auto font-weight-normal text-dark"
              style={{ backgroundColor: "white" }}
            >
              Edycja 1
            </p>
          </div>
          <Row className="px-md-5 h-75">
            <Col
              lg={okil1Current < 11 ? "8" : "4"}
              sm="12"
              className="mx-auto text-center thrd-colour"
            >
              <Carousel
                trackProps={{
                  onViewChange: (num) => {
                    setOkil1Current(num);
                  },
                }}
                frameProps={{ autoSize: "height" }}
                views={[...imagesOkil1].map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Col>
          </Row>
        </Row>
      </div>

      <ContactSummary refProp={myRef} />
      <ContactForm />
      <DemoFooter />
    </>
  );
}

export default FestivalView;
